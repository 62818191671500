<template>
    <v-dialog
            v-model="showModal"
            max-width="700"    
        >
        <v-card class="text-center"  full-width >
            <v-card-title >
              <v-row>
                <v-col cols="3" align="left">
                    <div class="text-h5 font-weight-bold">Filters</div>
                    <div class="text-subtitle-1 font-weight-light" style="color: #C4C4C4;">Select the sections</div>
                  
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
              
            </v-card-title>
            <v-card-text>
              <v-row>
                  <v-col cols="1" ></v-col>
                  <v-col >
                    <v-row >
                      <template>
                        <v-checkbox
                          class="mt-0"
                          v-model="checkAll"
                          @change="selectAll($event)"
                          label="All"
                          value="checkAll"
                        ></v-checkbox>
                        <div class="break"></div>
                      </template>
                    </v-row>
                    <v-row>
                      <v-col style="margin-left: 1rem; margin-top: -1rem;">
                        <!-- <template v-for="(section,i) in sectionArr"> 
                            <v-checkbox
                              class="mt-0"
                              v-model="selected"
                              :label="filterSection(section)"
                              :value="section"
                              :key="`checkbox-${i}`"
                            ></v-checkbox>
                            <div class="break" :key="`break-${i}`"></div>
                        </template> -->
                          <v-checkbox
                              style="margin-top: -1rem;"
                              v-model="selected"
                              label="SEC 001"
                              value="001"
                              :key="`checkbox-1`"
                            ></v-checkbox>
                            <v-checkbox
                              style="margin-top: -1rem;"
                              v-model="selected"
                              label="SEC 002"
                              value="002"
                              :key="`checkbox-2`"
                            ></v-checkbox>
                            <v-checkbox
                              style="margin-top: -1rem;"
                              v-model="selected"
                              label="SEC 003"
                              value="003"
                              :key="`checkbox-3`"
                            ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
      
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="dark"
                text
                @click="onClose()"
              >
                ยกเลิก
              </v-btn>
              <v-btn
                color="#69A5FF"
                class="white--text"
                type="submit"
      
              >
                บันทึก
              </v-btn>
              
            </v-card-actions> 
        </v-card>
    </v-dialog>
</template>

<script>
  export default {
    name: 'FilterComponent',
    components: {
    },
    props:{
      showModal : Boolean,
      dataList : Object
    },
    created(){
        
    },
    data() {
        return {
        }
    },
    methods:{
      onClose(){
          this.$emit('onClose')
      },
    }
  }
</script>

