<template>
    <div>
        <!-- <StudentListComponent/> -->
        <v-container fluid>
            <v-row justify='space-between' class="pb-5">
                <v-col>
                    <h1 style="color: #3E4555;">STUDENT LIST</h1>
                </v-col>
                <!-- term Course {{courseData.semester }} | term Curresnt {{ currentSemester }} -->
                <!-- <v-col class="text-right"  v-if=" currentSemester === courseData.semester || yearlySemester === courseData.semester || nextSemester === courseData.semester  ">
                    <CreateCMUExamModal  :courseData="courseData" @modalSubmit="onModalSubmit" :openModal="openCreateModal" @modalClose="openCreateModal = false"/>
                </v-col> -->
            </v-row>
        
            <v-card class="mt-1">
                <v-card-text>
                    <v-row justify='center'>
                        <v-col cols="8">
                            <router-link to="/"><div class="black--text"><i class="fas fa-caret-left"></i> ย้อนกลับ </div></router-link>

                            <div class="black--text font-weight-bold"> 006470 Seminar in Home and Community </div>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field
                                v-model="search"
                                label="Search"
                                prepend-inner-icon="mdi-magnify"
                                outlined
                                compact
                                dense
                                hide-details
                                clearable
                                append-icon="mdi-filter-outline"
                                @click:append="handleFilter()"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    
                </v-card-text>
                <v-divider></v-divider>
                <v-card-content >
                    <v-data-table
                        :headers="headers"
                        :items="desserts"
                        :search="search"
                    ></v-data-table>
                </v-card-content>
            </v-card>
            <FilterComponent :show-modal="showModalFilter" :data-list="[]" @onClose="handleFilter()"/>
        </v-container>
    </div>
</template>
<script>
import FilterComponent from '../components/StudentList/FilterComponent.vue'
// const config = require(`@/config`)
export default {
    
    name: 'StudentList',
    components: {
        FilterComponent
    },
    // created(){
    //     this.$store.commit('setSideBarData')
    // },
    computed: {
        // courseData: {
        //     get () {
        //         return this.$store.state.sideBarData
        //     },
        //     set () {
        //         this.$store.commit('setSideBarData')
        //     }
        // },
        // userData: {
        //     get () {
        //         return this.$store.state.userData
        //     },
        //     set () {
        //         this.$store.commit('setUserData')
        //     }
        // }
        
    },
    methods:{
        handleFilter(){
            console.log("click filter")
            this.showModalFilter = !this.showModalFilter 
        },
        // handleClose(){
        //     this.
        // }
    },
    data() {
        return {
            showModalFilter: false,
            search: '',
            headers: [
                {
                    align: 'start',
                    value: 'no',
                    sortable: false,
                    text: 'ลำดับ',
                    class:"black--text font-weight-bold",
                    style:"font-size:20px"

                    
                },
                { 
                    value: 'studentNumber',
                    text: 'รหัสนักศึกษา',
                    class:"black--text font-weight-bold"
                },
                { 
                    value: 'name',
                    text: 'ชื่อ-นามสกุล',
                    class:"black--text font-weight-bold"
                },
                { 
                    value: 'email',
                    text: 'อีเมล์',
                    class:"black--text font-weight-bold"
                },
                {   
                    value: 'section', 
                    text: 'ตอน',
                    class:"black--text font-weight-bold"
                },
            ],
            desserts: [
                {
                    no: 1,
                    studentNumber: 590610611,
                    name: "CHAWALID PUNYAJARERN",
                    email: "chawalidpunyajarern@gmail.com",
                    section: "001",
                },
                {
                    no: 2,
                    studentNumber: 590610611,
                    name: "CHAWALID PUNYAJARERN",
                    email: "chawalidpunyajarern@gmail.com",
                    section: "002",
                },
                {
                    no: 3,
                    studentNumber: 590610611,
                    name: "CHAWALID PUNYAJARERN",
                    email: "chawalidpunyajarern@gmail.com",
                    section: "003",
                },
                
            
            ],
        }
    }
    
  }
</script>